import 'react';
import { createRoot } from 'react-dom/client';
import { App } from './App';

import './index.scss';
import './helpers.scss';

createRoot(document.getElementById('root')!).render(
  <App/>
);
window.postMessage({ action: 'webapp_ready', data: null }, '*');
console.log('Should be ready');


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
