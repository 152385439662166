/// <reference types="vite-plugin-svgr/client" />
import { useState } from 'react';
import { v4 as uuid } from 'uuid';
import { FlexLayout, FlexChild } from 'xureact/lib/module/components/layout/flex-layout';

import { Layer } from './types/layer';
import { Whiteboard } from './whiteboard';
import { LayerManager } from './layer-manager';
//import { ReactComponent as EraserSVG } from './eraser.svg';
import EraserSVG from './eraser.svg?react';

import './App.scss';

const rainbowColors = ['#ff0000', '#ff7f00', '#ffff00', '#00ff00', '#0000ff', '#4b0082', '#9400d3', '#ffffff'];

const App = () => {
  const [color, setColor] = useState<string>(rainbowColors[0]);
  const [mode, setMode] = useState<'pencil' | 'eraser'>('pencil');
  const [layers, setLayers] = useState<Layer[]>([{id: uuid(), label:'Background', visible: true}]);
  const [selectedLayerId, setSelectedLayerId] = useState<string>(layers[0].id);

  const handleColorChange = (newColor: string) => {
    setColor(newColor);
    setMode('pencil');
  };

  const handleModeChange = (newMode: 'pencil' | 'eraser') => {
    setMode(newMode);
  };

  const handleAddLayer = (layerId: string) => {
    const newLayers = [
      { id: layerId, label: `Layer #${layers.length}`, visible: true},
      ...layers
    ];
    setLayers(newLayers);
    setSelectedLayerId(newLayers[0].id);
  };

  const handleSelectLayer = (layerId: string) => {
    setSelectedLayerId(layerId);
  };

  const handleToggleLayerVisible = (layerId: string) => {
    const index = layers.findIndex((layer) => layer.id === layerId);
    if (index > -1) {
      const newLayers = [...layers];
      newLayers[index].visible = !newLayers[index].visible;
      setLayers(newLayers);
    }
  };

  const handleDeleteLayer = (layerId: string) => {
    const newLayers = layers.filter((layer) => layer.id !== layerId);
    setLayers(newLayers);
  };

  const handleMoveLayerUp = (layerId: string) => {
    const index = layers.findIndex((layer) => layer.id === layerId);
    if (index > 0) {
      const newLayers = [...layers];
      [newLayers[index], newLayers[index - 1]] = [newLayers[index - 1], newLayers[index]];
      setLayers(newLayers);
    }
  };

  const handleMoveLayerDown = (layerId: string) => {
    const index = layers.findIndex((layer) => layer.id === layerId);
    if (index < layers.length - 1) {
      const newLayers = [...layers];
      [newLayers[index], newLayers[index + 1]] = [newLayers[index + 1], newLayers[index]];
      setLayers(newLayers);
    }
  };

  const layersReversed = [ ... layers ].reverse();

  return (
    <div className="fullh">
      <FlexLayout direction="column">
        <FlexChild grow={0}>
          <div className="color-palette">
            {rainbowColors.map((c, index) => (
              <div
                key={index}
                className={`color ${mode === 'pencil' && color === c ? 'active' : ''}`}
                style={{ backgroundColor: c }}
                onClick={() => handleColorChange(c)}
              />
            ))}
            <button
              className={`mode-button ${mode === 'eraser' ? 'active' : ''}`}
              onClick={() => handleModeChange('eraser')}
            >
              <EraserSVG fill={mode === 'eraser' ? 'red' : '#fff'}/>
            </button>
          </div>
        </FlexChild>
        <FlexChild grow={1}>
          <FlexLayout direction="row">
            <FlexChild grow={1}>
              <div className="whiteboard-area">
                {layersReversed.map((layer) => (
                  <Whiteboard key={layer.id} color={color} mode={mode} isEditing={layer.id === selectedLayerId} visible={layer.visible}/>
                ))}
              </div>
            </FlexChild>
            <FlexChild width={600}>
              <LayerManager
                layers={layers}
                selectedLayerId={selectedLayerId}
                onAddLayer={handleAddLayer}
                onSelectLayer={handleSelectLayer}
                onToggleLayerVisible={handleToggleLayerVisible}
                onDeleteLayer={handleDeleteLayer}
                onMoveLayerUp={handleMoveLayerUp}
                onMoveLayerDown={handleMoveLayerDown}
              />
            </FlexChild>
          </FlexLayout>
        </FlexChild>
      </FlexLayout>
    </div>
  );
};

export { App };
