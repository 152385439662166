/// <reference types="vite-plugin-svgr/client" />
import {Layer} from '/@/types/layer';
import { v4 as uuid } from 'uuid';

import EyeOpenSVG from './eye-open.svg?react';
import EyeClosedSVG from './eye-closed.svg?react';
import './layer-manager.scss';

interface LayerManagerProps {
  layers: Layer[];
  selectedLayerId: string;
  onAddLayer: (layerId: string) => void;
  onSelectLayer: (layerId: string) => void;
  onToggleLayerVisible: (layerId: string) => void;
  onDeleteLayer: (layerId: string) => void;
  onMoveLayerUp: (layerId: string) => void;
  onMoveLayerDown: (layerId: string) => void;
}

function LayerManager({
  layers,
  selectedLayerId,
  onAddLayer,
  onSelectLayer,
  onToggleLayerVisible,
  onDeleteLayer,
  onMoveLayerUp,
  onMoveLayerDown,
}: LayerManagerProps) {
  const handleAddLayer = () => {
    const newLayerId = uuid(); // Generate a unique identifier for the new layer
    onAddLayer(newLayerId);
  };

  return (
    <div className="layer-manager">
      <button onClick={handleAddLayer}>+</button>
      <ul>
        {layers.map((layer) => (
          <li key={layer.id} className={`${layer.id === selectedLayerId ? 'selected-layer' : ''}`}>
            <button onClick={() => onToggleLayerVisible(layer.id)}>
              {layer.visible ? <EyeOpenSVG fill="#fff"/> : <EyeClosedSVG fill="#fff"/>}
            </button>
            <span className="layer-manager__label" onClick={() => onSelectLayer(layer.id)}>
              {layer.label}
            </span>
            {/*<Whiteboard color="#004f8b" mode="pencil" currentLayer={index} />*/}
            <div className="layer-manager__buttons">
              <button onClick={() => onMoveLayerUp(layer.id)}>⬆</button>
              <button onClick={() => onMoveLayerDown(layer.id)}>⬇</button>
              <button disabled={layers.length <= 1} onClick={() => onDeleteLayer(layer.id)}>🗑</button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export {LayerManager};
