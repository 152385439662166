import { useState, useRef, useEffect } from 'react';

type Mode = 'pencil' | 'eraser';

type WhiteboardProps = {
  mode: Mode;
  color: string;
  isEditing: boolean;
  visible: boolean;
};

type Point = {
  x: number;
  y: number;
};

function Whiteboard({ mode, color, isEditing, visible }: WhiteboardProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [context, setContext] = useState<CanvasRenderingContext2D | null>(null);
  const [isDrawing, setIsDrawing] = useState<boolean>(false);
  const [prevPoint, setPrevPoint] = useState<Point | null>(null);
  const [resizeAnimFrame, setResizeAnimFrame] = useState<number>(-1);

  // FIXME feels hacky. This might be the right way to do so, but it feels... bad
  const [biggestImageData, _] = useState<{
    w: ImageData | null,
    h: ImageData | null,
  }>({
    w: null, h: null
  });

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      if (ctx) {
        setContext(ctx);
        resizeCanvas(ctx);
      }
    }
  }, []);

  const resizeCanvas = (context: CanvasRenderingContext2D | null) => {
    if (resizeAnimFrame > 0) {
      cancelAnimationFrame(resizeAnimFrame);
    }

    setResizeAnimFrame(requestAnimationFrame(() => {
      setResizeAnimFrame(-1);
      console.log('resizeCanvas');
      if (canvasRef.current && context) {
        const canvas = canvasRef.current;

        let imageData: ImageData | null = null;
        if (canvas.width > 0 && canvas.height > 0) {
          imageData = context.getImageData(0, 0, canvas.width, canvas.height);
        }

        const newCanvasWidth = (canvas.parentNode! as HTMLElement).offsetWidth; //Math.max(canvas.offsetWidth, originalCanvas.width / ratio);
        const newCanvasHeight = (canvas.parentNode! as HTMLElement).offsetHeight;
        console.log(newCanvasWidth, newCanvasHeight);

        if (newCanvasWidth > canvas.width) {
          // Extend the canvas size
          canvas.width = newCanvasWidth;
        }
        if (newCanvasHeight > canvas.height) {
          // Extend the canvas size
          canvas.height = newCanvasHeight;
        }

        if (imageData) {
          context.putImageData(imageData, 0, 0);
        }

        console.log('------------------------');
      }
    }));
  }

  useEffect(() => {
    const _resizeCanvas = () => resizeCanvas(context);
    window.addEventListener('resize', _resizeCanvas);
    return () => {
      window.removeEventListener('resize', _resizeCanvas);
    };
  }, [context]);

  const startDrawing = (event: React.MouseEvent<HTMLCanvasElement>) => {
    setIsDrawing(true);
    const canvas = canvasRef.current;
    if (canvas) {
      const { offsetX, offsetY } = event.nativeEvent;
      setPrevPoint({ x: offsetX, y: offsetY });
    }
  };

  const endDrawing = () => {
    if (context && prevPoint) {
      if (mode === 'pencil') {
        context.beginPath();
        context.arc(prevPoint.x, prevPoint.y, 3, 0, 2 * Math.PI, false);
        context.fillStyle = color;
        context.fill();
      }
    }
    setIsDrawing(false);
    setPrevPoint(null);
  };

  const draw = (event: React.MouseEvent<HTMLCanvasElement>) => {
    const canvas = canvasRef.current;
    if (context && isDrawing && prevPoint && canvas) {
      const { offsetX, offsetY } = event.nativeEvent;

      if (mode === 'pencil') {
        context.strokeStyle = color;
        context.lineJoin = 'round';
        context.lineWidth = 6;
        context.beginPath();
        context.moveTo(prevPoint.x, prevPoint.y);
        context.lineTo(offsetX, offsetY);
        context.closePath();
        context.stroke();
        setPrevPoint({ x: offsetX, y: offsetY });
      }
      else {
        erase(prevPoint.x, prevPoint.y, offsetX, offsetY);
        setPrevPoint({ x: offsetX, y: offsetY });
      }
    }
  };

  const erase = (x1: number, y1: number, x2: number, y2: number) => {
    if (context && canvasRef.current) {
      const ratio = window.devicePixelRatio;
      const dx = x2 - x1;
      const dy = y2 - y1;
      const distance = Math.sqrt(dx * dx + dy * dy);
      const steps = Math.ceil(distance); // Adjust based on desired step size

      for (let i = 0; i < steps; i++) {
        const x = x1 + dx * (i / steps);
        const y = y1 + dy * (i / steps);
        const eraserSize = 5 * 6 * ratio; // Eraser size
        context.clearRect(x - eraserSize / 2, y - eraserSize / 2, eraserSize, eraserSize);
      }
    }
  };

  return (
    <canvas
      className={`whiteboard-canvas ${isEditing ? 'editing' : ''} ${visible ? '' : 'hidden'}`}
      ref={canvasRef}
      style={{ border: '1px solid black' }}
      onMouseDown={startDrawing}
      onMouseUp={endDrawing}
      onMouseMove={draw}
    />
  );
}

export { Whiteboard };
